<template>
  <div>
    <div>
      <b-card bg-variant="danger" text-variant="white" :header="$t('Chyba')" class="text-center">
        
        <div class="w-100 text-center">
            <br><br><br>
            <h2>
                {{ $t("Došlo k závažné chybě.") }}
            </h2>
            <br><br>
            <p>
                {{ $t("To může být způsobeno neplatnými daty, změněným stavem systému nebo jinou chybou.") }}
            <br>
                {{ $t("Zkuste to znovu. Pokud problém přetrvává, kontaktujte podporu.") }}
            <br>
                {{ $t("Děkujeme.") }}
            </p>
            <b-button
                variant="primary"
                class="mb-2 btn-sm-block"
                :to="{ name: 'default-view', params: { }}"
            >
                {{ $t("Zpět") }}
            </b-button>
            <br><br><br>
        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import { /*state, mutations*/ } from '@/store/index.js'

export default {
  components: { },

  computed: { },

  data( ) {
    return {
    }
  },

  mounted( ) { },

  methods: { }
}
</script>

<style scoped>
</style>